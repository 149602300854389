import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function Sidebar(props) {
    const [displayCategories, setDisplayCategories] = useState(true);

    const onClick = useCallback(() => {
        setDisplayCategories(currentValue => !currentValue);
    }, []);

    return (
        <aside className="navbar navbar-dark bg-primary pl-0 pt-0 pr-0 sidebar z-index-0">
            <div className="sticky-top pt-0 pr-2 pb-2 pl-2 sidebar-body">
                <div>
                    <ul className="list-group list-group-flush">
                        <li onClick={onClick} className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                            Categories
                            <span className="">{displayCategories ? "-" : "+"}</span>
                            <span className="badge badge-primary badge-pill">{props.categories.length}</span>
                        </li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        {displayCategories &&
                            props.categories &&
                            props.categories.length > 0 &&
                            props.categories.map(category => {
                                return (
                                    <NavLink
                                        className="text-primary"
                                        key={category.name}
                                        to={`/category/${category.name}`}
                                        isActive={(match, location) => {
                                            if (match) {
                                                return true;
                                            } else {
                                                if (category.name === "Trending") {
                                                    return location.pathname === "/";
                                                }
                                                return false;
                                            }
                                        }}
                                        activeClassName="active-category"
                                    >
                                        <li
                                            key={category.name}
                                            data-category-name={category.name}
                                            className={`list-group-item d-flex justify-content-between align-items-center pl-5 pointer`}
                                        >
                                            {category.name}
                                            <span className="badge badge-primary badge-pill pointer-none">{category.totalTopics}</span>
                                        </li>
                                    </NavLink>
                                );
                            })}
                    </ul>
                </div>
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/knowledge-panel/topics">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Knowledge Panel <i className="fas fa-network-wired"></i>
                            </li>
                        </NavLink>
                    </ul>
                    {/* <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/deep-dive/categories" activeClassName="underline">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Categories</li>
                        </NavLink>
                        <NavLink className="text-primary" to="/deep-dive/topics" activeClassName="underline">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Topics</li>
                        </NavLink>
                    </ul> */}
                </div>
                {/* <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/deep-dive/categories">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Deep Dives <i className="fas fa-network-wired"></i>
                            </li>
                        </NavLink>
                    </ul>
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/deep-dive/categories" activeClassName="underline">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Categories</li>
                        </NavLink>
                        <NavLink className="text-primary" to="/deep-dive/topics" activeClassName="underline">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Topics</li>
                        </NavLink>
                    </ul>
                </div> */}
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/anso">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Censored Stories
                            </li>
                        </NavLink>
                    </ul>
                </div>
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/fun">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Best of the Web
                            </li>
                        </NavLink>
                    </ul>
                </div>
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/video_library">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Video Library <i className="fas fa-video"></i>
                            </li>
                        </NavLink>
                    </ul>
                </div>
                {/* <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/articles">
                            <li className="list-group-item d-flex justify-content-between align-items-center pointer font-weight-bold">
                                Articles <i className="fas fa-rss"></i>
                            </li>
                        </NavLink>
                    </ul>
                </div> */}
                {/* <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center font-weight-bold">Reports</li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/reports/twitter">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Twitter</li>
                        </NavLink>
                    </ul>
                </div> */}
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center font-weight-bold">Utilities</li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/deep-dive/topic/9H2eDWDn0lCr41Vcy3PZj/snapshots">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Top Stories</li>
                        </NavLink>
                        <NavLink className="text-primary" to="/deep-dive/topic/P0wwzoZVMoYXpSIle0vNV/related-topics">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Suggested Topics</li>
                        </NavLink>
                        <NavLink className="text-primary" to="/deep-dive/topic/V87-4FwLdtSXpvgB-OWeW">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Election 2024</li>
                        </NavLink>
                    </ul>
                </div>
                <div className="mt-2">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center font-weight-bold">Crawler</li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        <NavLink className="text-primary" to="/crawler">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Lookup</li>
                        </NavLink>
                        <NavLink className="text-primary" to="/crawler/add">
                            <li className="list-group-item d-flex justify-content-between align-items-center pl-5 pointer">Add</li>
                        </NavLink>
                    </ul>
                </div>
            </div>
        </aside>
    );
}

Sidebar.propTypes = {
    categories: PropTypes.array.isRequired
};

export default Sidebar;
