import React from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data
    });

    return (
        <div className="tab-content w-100">
            <div style={{ overflow: "scroll" }} className="tab-pane active mt-2">
                <table className="table table-hover" {...getTableProps()} style={{ height: "fit-content" }}>
                    <thead className="table-primary text-light">
                        {headerGroups.map((headerGroup, i) => (
                            // eslint-disable-next-line jsx-a11y/scope
                            <tr scope="col" key={i} {...headerGroup.getHeaderGroupProps()} style={{ height: "100%" }}>
                                {headerGroup.headers.map((column, j) => (
                                    <th key={j} {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row, g) => {
                            prepareRow(row);
                            return (
                                <tr key={g} {...row.getRowProps()}>
                                    {row.cells.map((cell, k) => {
                                        return (
                                            <td style={{ verticalAlign: "middle" }} key={k} {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default Table;
