// @ts-check

import PropTypes from "prop-types";
import React from "react";
import { toTimeAgo } from "../../../utils/utils";
import { AddDocumentModal } from "./AddDocumentModal";
import { DeleteDocumentModal } from "./DeleteDocumentModal";
import { UpdateDocumentModal } from "./UpdateDocumentModal";

/**
 * @param {object} props
 * @param {import("./PinnedDocumentTypes").Document[]} props.documents
 * @param {string} props.storyId
 * @param {() => Promise<void>} props.onChangeDocuments
 */
function PinnedDocuments({ documents, storyId, onChangeDocuments }) {
    return (
        <>
            <div className="PinnedDocuments">
                <AddDocumentModal storyId={storyId} onAdd={onChangeDocuments} />
                <div className="d-flex flex-row flex-wrap" style={{ gap: 32 }}>
                    {documents?.map(doc => (
                        <div key={doc.id} className="card" style={{ width: 180 }}>
                            {doc.images?.[0] && <img className="card-img-top" src={doc.images[0]} alt="" />}
                            <div className="card-body d-flex flex-column justify-content-space-between" style={{ lineHeight: 1, height: "100%" }}>
                                <div>
                                    <h5 className="card-title mb-0">
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">
                                            {doc.title}
                                        </a>
                                    </h5>
                                    <p className="card-text mb-1 mt-2">{doc.description}</p>
                                    {doc.date_published && (
                                        <small className="text-muted" title={doc.date_published?.toString()}>
                                            {toTimeAgo(doc.date_published)}
                                        </small>
                                    )}
                                </div>
                                <div className="d-flex flex-row justify-content-space-between mt-auto pt-3" style={{ width: "100%" }}>
                                    <UpdateDocumentModal storyId={storyId} document={doc} onEdit={onChangeDocuments} />
                                    <DeleteDocumentModal storyId={storyId} documentId={doc.id} onDelete={onChangeDocuments} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

PinnedDocuments.propTypes = {
    documents: PropTypes.array,
    storyId: PropTypes.string
};

export default PinnedDocuments;
