import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import axios from "axios";
import uniqBy from "lodash-es/uniqBy";
import cloneDeep from "lodash-es/cloneDeep";
import { getUnixTime, parseISO } from "date-fns";
import { useAuth } from "react-oidc-context";
import { getAuthHeaders } from "../../utils/utils.js";
import { BACKEND_URL } from "../../config.js";
import Article from "../article.js";
import Tweet from "../tweet.js";
import PinNewContent from "./pinNewContent";
import { getImageSourceList } from "../../utils/utils.js";

function TopicPreview(props) {
    const { user } = useAuth();
    const [contentList, updateContentList] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const isSatire = props.categoryName === "Make me laugh";

    const removeDuplicates = data => {
        return uniqBy(data, "id");
    };

    const prependPinnedContent = useCallback(
        array => {
            let pinnedContent = cloneDeep(props.pinnedContent);
            // remove pinned content that is expired
            pinnedContent = pinnedContent.map(content => {
                if (content.tweet_id) {
                    // temp hack
                    // content.id = content.tweet_id;
                    return content;
                }
                content.full_info.isPinned = true;
                content.full_info.id = content.id;

                return content.full_info;
            });

            // sortByDate(pinnedContent, ["position", "datePublished"]);

            array.unshift(...pinnedContent);
        },
        [props.pinnedContent]
    );

    const normalizeData = useCallback(data => {
        let resultsArr = [];

        // to support new payload after gRPC integration
        if (Array.isArray(data)) {
            resultsArr.push(...data);
        }
        return resultsArr;
    }, []);

    const fetchArticles = useCallback(async () => {
        try {
            const headers = getAuthHeaders(user.access_token);
            const query = props.topic.query || props.topic.name || "";
            const tags = props.topic.enable_search_by_tags && Array.isArray(props.topic.tags) ? props.topic.tags.join(",") : "";

            let normalizedData = [];

            // disable organic results
            if (!props.topic.disable_organic_results) {
                const bannedArticlesIdList = props.topic?.bannedArticles;
                const result = await axios.get(`${BACKEND_URL}/news/search`, {
                    params: {
                        q: query,
                        perPage: 10,
                        bias: "",
                        signImages: true,
                        tags: isSatire ? "satire" : tags,
                        format: "group_by_bias",
                        excludeArticlesIdList: bannedArticlesIdList || undefined,
                        ...(props.topic.result_start_date && { startDate: getUnixTime(parseISO(props.topic.result_start_date)) }),
                        ...(props.topic.result_end_date && { endDate: getUnixTime(parseISO(props.topic.result_end_date)) })
                    },
                    headers
                });
                normalizedData = normalizeData(result.data);
            }

            // sortByDate(normalizedData, "datePublished");

            prependPinnedContent(normalizedData);

            normalizedData = removeDuplicates(normalizedData);

            // find and pass topic image to the parent 'topic' component
            props.exportTopicImage(getTopicImg(normalizedData));

            ReactDOM.unstable_batchedUpdates(() => {
                setError(null);
                setLoading(false);
                updateContentList(normalizedData);
            });
        } catch (e) {
            ReactDOM.unstable_batchedUpdates(() => {
                setLoading(false);
                setError(e.toString());
            });
        }
    }, [isSatire, user.access_token, normalizeData, prependPinnedContent, props]);

    useEffect(() => {
        fetchArticles();
    }, [fetchArticles, props.topicLastUpdated]);

    return (
        <div className="topic-preview mt-2">
            <PinNewContent categoryName={props.categoryName} pinNewContent={props.onPin} />
            {isLoading ? (
                <div className="spinner-border mx-auto" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : null}
            {error ? <span>{error}</span> : null}
            {contentList && contentList.length ? (
                <div className="topic-preview-container">
                    {contentList.map((content, i) => {
                        const isBanned = props.topic.bannedArticles ? props.topic.bannedArticles.includes(content.id) : false;
                        if (content.tweet_id) {
                            return (
                                <Tweet
                                    key={content.id}
                                    tweet={content}
                                    isPinned={false}
                                    isPreview={true}
                                    index={i}
                                    onUpdate={props.onUpdateTweet}
                                    onDelete={props.onDeleteTweet}
                                />
                            );
                        }
                        return (
                            <Article
                                key={content.id}
                                article={{
                                    full_info: content,
                                    custom_tag: content.custom_tag,
                                    custom_tag_bg_color: content.custom_tag_bg_color,
                                    custom_tag_text_color: content.custom_tag_text_color,
                                    id: content.id
                                }}
                                isPinned={false}
                                isPreview={true}
                                index={i}
                                isSatire={isSatire}
                                addArticleToBanlist={props.addArticleToBanlist}
                                removeArticleFromBanlist={props.removeArticleFromBanlist}
                                storyId={props.topic.id}
                                story={props.topic}
                                isBanned={isBanned}
                                setTopicImage={props.setTopicImage}
                                onPin={props.onPin}
                                onUpdate={props.onUpdateArticle}
                                onDelete={props.onDeleteArticle}
                            />
                        );
                    })}
                </div>
            ) : !isLoading ? (
                <div>No articles found</div>
            ) : null}
        </div>
    );
}

// const removeDuplicates = data => {
//     return uniqBy(data, "id");
// };

const getTopicImg = articles => {
    let img = [];
    articles.forEach(article => {
        if (!img.length) {
            const images = getImageSourceList(article);
            img.push(...images);
        }
    });
    return img[0];
};

TopicPreview.propTypes = {
    topic: PropTypes.object.isRequired,
    topicLastUpdated: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    setTopicImage: PropTypes.func.isRequired,
    addArticleToBanlist: PropTypes.func.isRequired,
    removeArticleFromBanlist: PropTypes.func,
    exportTopicImage: PropTypes.func.isRequired,
    onUpdateArticle: PropTypes.func,
    onUpdateTweet: PropTypes.func,
    onDeleteArticle: PropTypes.func,
    onDeleteTweet: PropTypes.func,
    onPin: PropTypes.func.isRequired
};

export default TopicPreview;
