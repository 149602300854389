/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { isValidHttpUrl, toTimeAgo } from "../../../utils/utils";
import ConfirmPopup from "../../utils/confirmPopup";
import { formatDuration } from "../../utils/duration";
import Table from "../../utils/table";
import VideoModal from "./addOrEditVideo";
import { fetchVideosPinnedToAStory, reorderVideoContent, unlinkVideoFromAStory } from "./storyVideosAPI";
import "./video.scss";

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

function PinnedVideosTab({ storyId, refreshParentData }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [contentToEdit, setContentToEdit] = useState(false);
    const [openNewContentModal, setOpenNewContentModal] = useState(false);
    const [content, setContent] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchVideos = useCallback(() => {
        setIsFetching(true);
        fetchVideosPinnedToAStory({ story_id: storyId, page: 1, per_page: 25 }, user.access_token)
            .then(data => {
                setContent(data.data);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, storyId]);

    useEffect(() => {
        fetchVideos();
    }, [fetchVideos, refreshParentData]);

    const requestRefresh = useCallback(() => fetchVideos(), [fetchVideos]);

    const repositionContent = useCallback(
        (itemId, direction) => {
            let contentToMoveId = "";
            let moveAfterContentId = "";
            const currentIdx = content.findIndex(x => x.id === itemId);

            if (direction === "up") {
                contentToMoveId = content[currentIdx - 1].id;
                moveAfterContentId = itemId;
            } else {
                contentToMoveId = itemId;
                moveAfterContentId = content[currentIdx + 1].id;
            }

            const request = { story_id: storyId, content_to_move_id: contentToMoveId, move_after_content_id: moveAfterContentId };
            reorderVideoContent(request, user.access_token)
                .then(() => refreshParentData())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [content, storyId, user.access_token, refreshParentData, alert]
    );

    const unlinkContent = useCallback(
        content => {
            unlinkVideoFromAStory({ storyId, videoId: content.id }, user.access_token)
                .then(() => refreshParentData())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [storyId, user.access_token, refreshParentData, alert]
    );

    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Image</span>,
                accessor: "images",
                Cell: row => (
                    <div className="position-relative d-flex flex-column align-items-center pb-4" style={{ height: "100%" }}>
                        <div className="position-absolute" style={{ top: 100 }}>
                            {content[row.row.index].bias ? (
                                <span title={`${content[row.row.index].bias} bias `}>
                                    <i className={`fas fa-circle ${content[row.row.index].bias}`}></i>
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        {(!Array.isArray(row.value) || !row.value.length) && (
                            <span className="position-absolute translate-middle">No image selected</span>
                        )}
                        {Array.isArray(row.value) && row.value[0] && !isValidHttpUrl(row.value[0]) && (
                            <span className="position-absolute translate-middle">Invalid image URL</span>
                        )}

                        <img
                            style={{ objectFit: "cover", minWidth: "125px", height: "75px" }}
                            className="img-thumbnail m-0 p-0"
                            src={isValidHttpUrl(row.value?.[0]) ? row.value[0] : noImageDataURI}
                            alt="topic"
                        />
                    </div>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Info</span>,
                accessor: "title",
                Cell: row => (
                    <>
                        <p onClick={() => setContentToEdit(content[row.row.index])} className="d-inline-flex align-middle m-0 pointer text-wrap">
                            <strong>{row.value}</strong>
                        </p>

                        {content[row.row.index]?.description && (
                            <p onClick={() => setContentToEdit(content[row.row.index])} className="d-inline-flex align-middle m-0 pointer text-wrap">
                                {content[row.row.index].description}
                            </p>
                        )}
                        <p style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 4, marginTop: 4 }}>
                            <u>Host: </u>
                            {content[row.row.index].publisher_logo && (
                                <img
                                    alt="publisher logo"
                                    style={{ objectFit: "contain", maxWidth: "60px", height: "24px" }}
                                    src={content[row.row.index].publisher_logo}
                                ></img>
                            )}
                            {content[row.row.index].publisher_name && <span>{content[row.row.index].publisher_name}</span>}
                        </p>

                        <p style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 4, marginTop: 4 }}>
                            <u>Published: </u>
                            {content[row.row.index].date_published && <span>{toTimeAgo(content[row.row.index].date_published)}</span>}
                        </p>

                        <p style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 4 }}>
                            <u>Channel:</u>
                            {content[row.row.index]?.channel_url ? (
                                <a href={content[row.row.index].channel_url} target="_blank" rel="noopener noreferrer">
                                    <span className="text-info">{content[row.row.index].channel_title}</span>
                                </a>
                            ) : (
                                <span>{content[row.row.index].channel_title}</span>
                            )}
                        </p>

                        {typeof content[row.row.index]?.duration === "number" && (
                            <p style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 4 }}>
                                <u>Duration:</u>
                                {<span>{formatDuration(content[row.row.index].duration)}</span>}
                            </p>
                        )}
                    </>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    return (
                        <div style={{ minWidth: "120px" }}>
                            {/* <button
                                onClick={() => setContentToEdit(content[row.row.index])}
                                className="btn btn-outline-primary btn-sm"
                            >
                                Edit
                            </button> */}
                            <ConfirmPopup onConfirm={() => unlinkContent(row.data[row.row.index])}>
                                <button className="btn btn-outline-danger btn-sm ml-2">Delete</button>
                            </ConfirmPopup>
                            <button
                                onClick={() => repositionContent(row.data[row.row.index].id, "up")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-up"></i>
                            </button>
                            <button
                                onClick={() => repositionContent(row.data[row.row.index].id, "down")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    );
                }
            }
        ],
        [content, repositionContent, unlinkContent]
    );

    const onSuccess = useCallback(() => {
        setContentToEdit(false);
        setOpenNewContentModal(false);
        requestRefresh();
    }, [requestRefresh]);

    return (
        <div className="mt-3 PinnedVideos">
            {contentToEdit && (
                <VideoModal type="edit" content={contentToEdit} storyId={storyId} onSuccess={onSuccess} onClose={() => setContentToEdit(false)} />
            )}
            {openNewContentModal ? (
                <VideoModal type="new" storyId={storyId} onSuccess={onSuccess} onClose={() => setOpenNewContentModal(false)} />
            ) : (
                <div className="d-flex justify-content-center mt-2">
                    <span onClick={() => setOpenNewContentModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            {content && <Table columns={columns} data={content} />}
            {isFetching ? (
                <div className="mx-auto spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                content && content.length === 0 && <p className="text-center">No items</p>
            )}
        </div>
    );
}

PinnedVideosTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default PinnedVideosTab;
