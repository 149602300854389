// @ts-check

import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import DatePicker from "../../utils/datePicker";
import Modal from "../../utils/modal";
import { updateDocument } from "./pinnedDocumentsApi";

import { getHttpErrorMessage } from "../../../utils/utils";
import "./updateDocumentModal.scss";

/**
 * @typedef {object} UpdateDocumentFormData
 * @property {string} document_id
 * @property {string=} title
 * @property {string=} description
 * @property {?Date=} date
 * @property {string=} image
 */

/**
 * @param {object} props
 * @param {string} props.storyId
 * @param {import("./PinnedDocumentTypes").Document} props.document
 * @param {() => Promise<void>} props.onEdit
 */
export function UpdateDocumentModal({ storyId, document, onEdit }) {
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState(
        /** @type {UpdateDocumentFormData} */ ({
            document_id: document.id,
            title: document.title,
            description: document.description,
            date: document.date_published ? new Date(document.date_published) : null,
            image: document.images?.[0] ?? ""
        })
    );

    useEffect(() => {
        setFormData({
            document_id: document.id,
            title: document.title,
            description: document.description,
            date: document.date_published ? new Date(document.date_published) : null,
            image: document.images?.[0] ?? ""
        });
    }, [document.date_published, document.description, document.id, document.images, document.title]);

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onInputChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = useCallback(async () => {
        if (!storyId || !formData.document_id || isSubmitting) {
            return;
        }
        try {
            if (!user?.access_token) {
                throw new Error("unauthenticated");
            }

            setIsSubmitting(true);
            setError(null);
            await updateDocument({
                token: user.access_token,
                document: {
                    ...formData,
                    date: formData.date?.toISOString(),
                    image: formData.image || undefined
                }
            });
            await onEdit();
            setIsOpen(false);
        } catch (e) {
            setError(getHttpErrorMessage(e));
        } finally {
            setIsSubmitting(false);
        }
    }, [formData, isSubmitting, onEdit, storyId, user?.access_token]);

    return (
        <>
            <button onClick={() => setIsOpen(true)} className="btn btn-info pointer">
                <i className="fas fa-edit"></i>
            </button>
            <Modal
                className="update-document-modal"
                label="Update Document"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                closeClassName="update-document-modal-close"
            >
                <div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="title">
                            <span className="text-danger">* </span>Title:
                        </label>
                        <input
                            id="title"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="title"
                            type="text"
                            value={formData.title}
                            placeholder="Enter a title"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="description">
                            Description:
                        </label>
                        <input
                            id="description"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="description"
                            type="text"
                            value={formData.description}
                            placeholder="Enter a description"
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-control-label mt-2" htmlFor="date">
                            Date:
                        </label>
                        <div className="w-100">
                            <DatePicker
                                id="date"
                                className="form-control bg-white pl-2"
                                isClearable
                                todayButton="Now"
                                selected={formData.date}
                                onChange={newDate => {
                                    if (Array.isArray(newDate)) {
                                        newDate = newDate[0];
                                    }
                                    setFormData({ ...formData, date: newDate });
                                }}
                                placeholderText="Click to select a date"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="image">
                            Image Url:
                        </label>
                        <input
                            id="image"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="image"
                            type="url"
                            value={formData.image}
                            placeholder="https://..."
                        />
                    </div>

                    <button className="btn btn-info" disabled={isSubmitting} onClick={onSubmit}>
                        {isSubmitting ? "Updating" : "Update Document"}
                    </button>
                    {error && <span className="text-danger">{error}</span>}
                </div>
            </Modal>
        </>
    );
}
