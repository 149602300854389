import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import Modal from "react-modal";
import TwitterIcon from "../static/svg/twitter.svg";
// import CategoryLinking from "../components/modals/categoryLinking";
import ConfirmPopup from "./utils/confirmPopup";
import { toTimeAgo } from "../utils/utils";
import DatePicker from "../components/utils/datePicker";
import { ReactComponent as WinnerRibbonSVG } from "../svg/winner-logo.svg";
import "./tweet.scss";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Tweet(props) {
    const [formData, setFormData] = useState({
        ...(props.isBreakingNews && { expires_at: props.tweet.expires_at ? format(new Date(props.tweet.expires_at), "yyyy-MM-dd'T'hh:mm") : "" }),
        bias: props.tweet.bias,
        datePublished: props.tweet.datePublished
    });
    const [modalIsOpen, setIsOpen] = useState(false);
    // const [openCategoryLinking, setOpenCategoryLinking] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onPositionUpdate = useCallback(() => {
        props.onPositionUpdate(props.tweet.id);
    }, [props]);

    const alert = useAlert();

    const onUpdate = useCallback(() => {
        props.onUpdate(props.tweet.id || props.parentId, formData);
        closeModal();
    }, [formData, props]);

    const onInputChange = e => {
        if (e.target.type === "radio") {
            setFormData({ ...formData, bias: e.target.name });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const onDateChange = updatedData => {
        setFormData({ ...formData, datePublished: updatedData });
    };

    const resetExpiration = () => {
        setFormData({ ...formData, expires_at: "" });
    };

    // const onSave = (data) => {
    //     props.onUpdate(props.tweet.id, data);
    //     setOpenCategoryLinking(false);
    // };

    const isExpired = props.tweet.expires_at ? new Date(props.tweet.expires_at) < Date.now() : null;
    const datePublished = new Date(props.tweet.datePublished);

    return (
        <div className={`tweet card mb-2 ${formData.bias || "no-bias"}`}>
            {/* {openCategoryLinking && (
                <CategoryLinking
                    isOpen={true}
                    onClose={() => setOpenCategoryLinking(false)}
                    user={props.user}
                    onSave={onSave}
                    parentId={props.parentId}
                    data={props.parentData}
                />
            )} */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Article settings">
                <div className="modal-body">
                    <div className="form-group has-danger">
                        <div className="bias-selectors">
                            <div className="custom-control custom-radio d-inline-block">
                                <input
                                    onChange={onInputChange}
                                    type="radio"
                                    id="left"
                                    name="left"
                                    className="custom-control-input"
                                    checked={formData.bias === "left"}
                                />
                                <label className={`custom-control-label ${formData.bias === "left" ? "left" : ""}`} htmlFor="left">
                                    LEFT
                                </label>
                            </div>
                            <div className="custom-control custom-radio d-inline-block ml-2">
                                <input
                                    onChange={onInputChange}
                                    type="radio"
                                    id="middle"
                                    name="middle"
                                    className="custom-control-input"
                                    checked={formData.bias === "middle"}
                                />
                                <label className={`custom-control-label ${formData.bias === "middle" ? "middle" : ""}`} htmlFor="middle">
                                    MIDDLE
                                </label>
                            </div>
                            <div className="custom-control custom-radio d-inline-block ml-2">
                                <input
                                    onChange={onInputChange}
                                    type="radio"
                                    id="right"
                                    name="right"
                                    className="custom-control-input"
                                    checked={formData.bias === "right"}
                                />
                                <label className={`custom-control-label ${formData.bias === "right" ? "right" : ""}`} htmlFor="right">
                                    RIGHT
                                </label>
                            </div>
                        </div>

                        <label className="form-control-label mt-4" htmlFor="datePublished">
                            Date published: <span>({toTimeAgo(formData.datePublished)})</span>
                        </label>
                        <div className="w-100" style={{ marginBottom: 300 }}>
                            <DatePicker
                                showTimeInput
                                name="datePublished"
                                className="form-control bg-white pl-2"
                                selected={formData.datePublished ? new Date(formData.datePublished) : null}
                                onChange={onDateChange}
                                placeholderText="Click to select a date"
                            />
                        </div>

                        {/* <label className="control-label mt-3" htmlFor="disabledInput">
                            Choose date and time when the article should be unpinned:
                        </label>
                        <input
                            onChange={onInputChange}
                            className="form-control"
                            name="expires_at"
                            type="datetime-local"
                            value={formData.expires_at}
                            placeholder="Choose date and time"
                        /> */}
                        {formData.expires_at ? (
                            <span onClick={resetExpiration} className="float-right pr-1 pointer text-info">
                                Clear
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={closeModal} className="btn btn-secondary">
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={onUpdate}>
                        Save changes
                    </button>
                </div>
            </Modal>
            <div className="header mt-2">
                <div className="author-avatar">
                    <img className="tweet-img" src={props.tweet.author.profile_image_url} alt="tweet"></img>
                </div>
                <div className="author-details mt-1 ml-2">
                    <p className="author-name m-0">{props.tweet.author.name}</p>
                    <a
                        className="author-username"
                        href={`https://twitter.com/${props.tweet.author.username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span title="Twitter" className="text-muted">{`@${props.tweet.author.username}`}</span>
                    </a>
                </div>
                <div className="icons-container">
                    <CopyToClipboard
                        className="pointer text-center"
                        text={props.tweet.url}
                        onCopy={() => {
                            alert.show("copied", { timeout: 1000, type: "info" });
                        }}
                    >
                        <span>
                            <i className="far fa-copy"></i>
                        </span>
                    </CopyToClipboard>
                    <div className="twitter-icon mt-2">
                        <img src={TwitterIcon} alt="tweet icon" />
                    </div>
                </div>
            </div>
            <div className="tweet-body card-body py-2">
                <small className="text-muted" title={datePublished.toString()}>
                    {toTimeAgo(datePublished)}
                </small>
                {formData.bias ? (
                    <div className={`bias-circle ${formData.bias}`}>
                        <span>{formData.bias.toUpperCase()[0]}</span>
                    </div>
                ) : null}
                <a href={props.tweet.url} target="_blank" rel="noopener noreferrer">
                    <p className="tweet-text card-text text-primary">{props.tweet.text}</p>
                </a>
            </div>
            {/* {props.isBreakingNews && (
                <div className="tweet-category-link text-muted py-1 ml-3">
                    <span onClick={() => setOpenCategoryLinking(true)} className={`badge badge-pill badge-${linkToCategory ? "success" : "primary"} pointer`}>
                        {linkToCategory ? `Linked to ${linkedToName}` : "Click to link to a category"}
                    </span>
                </div>
            )} */}
            <div className="article-footer card-footer border-top-0 text-muted py-1 px-2">
                {(props.isPinned || props.isBreakingNews || props.isPreview) && (
                    <React.Fragment>
                        {props.showPosition && (
                            <div className="d-inline-block ml-2 item-position-box">
                                <span title="Display position in a single list">{props.itemPosition + 1}</span>
                            </div>
                        )}
                        {props.showPosition && (
                            <ConfirmPopup title="Move this tweet to position #1 ?" onConfirm={onPositionUpdate}>
                                <span className="winner-ribbon ml-2 float-right pointer">
                                    <WinnerRibbonSVG />
                                </span>
                            </ConfirmPopup>
                        )}
                        <span onClick={openModal} title="Set expiration" className="d-inline-block pointer ml-2">
                            <i className="far fa-edit"></i>
                        </span>
                        {!props.isPreview && props.tweet.expires_at ? (
                            <span className={`ml-2 ${isExpired ? "text-danger" : ""}`}>
                                {isExpired
                                    ? "Expired"
                                    : `Expires ${formatDistanceToNowStrict(new Date(props.tweet.expires_at), { addSuffix: true })}`}
                            </span>
                        ) : null}

                        {props.isPreview && <span className="badge badge-pill badge-warning mx-auto">Pinned</span>}
                        <ConfirmPopup
                            title="Unpin this tweet?"
                            onConfirm={() => props.onDelete(props.isBreakingNews ? props.parentId : props.tweet.id)}
                        >
                            <span className="float-right pointer mr-2 ml-auto">
                                <i className="fas fa-trash-alt"></i>
                            </span>
                        </ConfirmPopup>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

Tweet.propTypes = {
    tweet: PropTypes.object.isRequired,
    parentData: PropTypes.object,
    parentId: PropTypes.string,
    itemPosition: PropTypes.number,
    isBreakingNews: PropTypes.bool,
    isPreview: PropTypes.bool,
    isPinned: PropTypes.bool,
    categories: PropTypes.array,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onPositionUpdate: PropTypes.func
};

export default Tweet;
