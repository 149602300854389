import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";

function ConfirmPopup({
    children,
    content,
    title = "Are you sure?",
    confirmText = "Confirm",
    cancelText = "Cancel",
    onCancel,
    onConfirm,
    maxWidth = 200
}) {
    const close = () => document.body.click();

    const onCancelClick = () => {
        close();
        if (typeof onCancel === "function") {
            onCancel();
        }
    };

    const onConfirmClick = () => {
        close();
        onConfirm();
    };

    const popover = (
        <Popover id="popover-basic" style={{ maxWidth }}>
            <Popover.Title as="h3" className="text-primary text-center">
                {title}
            </Popover.Title>
            <Popover.Content>
                {content && content}
                <button onClick={onCancelClick} className="btn btn-outline-primary btn-sm mr-3">
                    {cancelText}
                </button>
                <button onClick={onConfirmClick} className="btn btn-danger btn-sm float-right">
                    {confirmText}
                </button>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose trigger="click" placement="top" overlay={popover}>
            {children}
        </OverlayTrigger>
    );
}

ConfirmPopup.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node,
    title: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    maxWidth: PropTypes.number
};

export default ConfirmPopup;
