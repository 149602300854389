import axios from "axios";
import { getAuthHeaders } from "../utils/utils";
import { BACKEND_URL } from "../config";

export async function getCrawlerSources(domain, page, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.get(`${BACKEND_URL}/admin/crawler/${encodeURIComponent(domain)}/sources?page=${page}&perPage=20`, { headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

export async function discoverCrawlerSources(domain, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.get(`${BACKEND_URL}/admin/crawler/${encodeURIComponent(domain)}/sources:discover`, { headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

export async function addCrawlerSources(domain, sources, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.post(
            `${BACKEND_URL}/admin/crawler/${encodeURIComponent(domain)}/sources`,
            { sources: sources, hostname: domain },
            { headers }
        );
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}
