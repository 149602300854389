import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { isTweetURL, isValidHttpUrl } from "../../utils/utils.js";

const DEFAULT_ERROR = "Invalid URL format. Supported format for articles: https://example.com/?freespoke_id=123, for tweets: https://twitter.com/SpaceX/status/1315743656716853253";

function PinNewContent(props) {
    const [showAddNewInput, setShowAddNewInput] = useState(false);
    const [URLValidationError, setURLValidationError] = useState("");
    const [newContent, setNewContent] = useState({ url: "" });

    const onChange = e => {
        const content = {
            ...newContent,
            [e.target.name]: e.target.value,
            createdAt: new Date()
        };
        const validURL = isValidHttpUrl(content.url);
        const isTweet = isTweetURL(content.url);

        if (isTweet) {
            content.tweet_id = content.url.split("/status/")[1];
            if (content.tweet_id.includes("?")) {
                content.tweet_id = content.tweet_id.split("?")[0];
            }
        } else {
            content.tweet_id = null;
        }
        ReactDOM.unstable_batchedUpdates(() => {
            setNewContent(content);
            if (validURL) {
                if (isTweet && props.categoryName === "Make me laugh") {
                    setURLValidationError('Currently tweets not support under "Make Me Laugh (Fun)" category');
                } else {
                    setURLValidationError("");
                }
            } else {
                setURLValidationError(DEFAULT_ERROR);
            }
        });
    };

    const pinNewContent = () => {
        if (!newContent.url) return;
        props.pinNewContent(newContent);
        setNewContent({ url: "" });
    };

    const inValidFeedbackMgs = props.categoryName !== "Make me laugh" ? DEFAULT_ERROR : 'Currently tweets not support under "Make Me Laugh (Fun)" category.';

    if (!showAddNewInput) {
        return (
            <div className={`d-flex justify-content-center my-2 mb-3 ${props.className || ""}`}>
                <span onClick={() => setShowAddNewInput(true)}>
                    <i className="fas fa-plus text-secondary pointer"></i>
                </span>
            </div>
        );
    }

    return (
        <div className={`input-group my-2 mb-3 ${props.className || ""}`}>
            <div className="input-group-prepend">
                <span onClick={() => setShowAddNewInput(false)} className="input-group-text pointer">
                    <i className="fas fa-times"></i>
                </span>
            </div>
            <input type="url" onChange={onChange} value={newContent.url} name="url" className={`form-control ${URLValidationError ? "is-invalid" : ""}`} placeholder="Add article or Tweet URL" />
            <div className="invalid-feedback">{inValidFeedbackMgs}</div>
            {!URLValidationError && (
                <div onClick={pinNewContent} className="input-group-append pointer">
                    <span className="input-group-text">
                        <i className="fas fa-plus text-success"></i>
                    </span>
                </div>
            )}
        </div>
    );
}

PinNewContent.propTypes = {
    className: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    pinNewContent: PropTypes.func
};

export default PinNewContent;
