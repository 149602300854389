/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import DatePicker from "../../utils/datePicker";
import { isValidHttpUrl } from "../../../utils/utils";
import ScrapeAPI from "../../../api/scrapeAPI";
import { createAndPinVideoToAStory, updateVideo } from "./storyVideosAPI";
import { parseDuration } from "../../utils/duration";
import { displayAPIError } from "../../errors/error";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function ContentModal({ type, content, storyId, onClose, onSuccess }) {
    const isEdit = type === "edit";
    const alert = useAlert();
    const { user } = useAuth();
    const [isScraping, setIsScraping] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState(() => {
        if (isEdit) {
            return {
                content_url: content.content_url || "",
                duration: content.duration || 0,
                title: content.title || "",
                description: content.description || "",
                bias: content.bias || "",
                publisher_name: content.publisher_name || "",
                date_published: content.date_published || "",
                image_url: content.image_url || "",
                publisher_logo: content.publisher_logo || "",
                channel_title: content.channel_title || "",
                channel_url: content.channel_url || ""
            };
        }
        return {
            content_url: "",
            duration: 0,
            title: "",
            description: "",
            bias: "",
            publisher_name: "",
            date_published: "",
            image_url: "",
            publisher_logo: "",
            channel_title: "",
            channel_url: ""
        };
    });

    const onScrapeClick = () => {
        if (!isValidHttpUrl(formData.content_url)) {
            return alert.show("Invalid URL");
        }

        setIsScraping(true);

        ScrapeAPI.scrapeMetadataFromUrl(formData.content_url, user.access_token)
            .then(data => {
                // prettier-ignore
                if (data) {
                    const datePublished = data?.datePublished ? new Date(data.datePublished) : null;

                    const prefilledData = prefillValuesForWellknownDomains(formData.content_url);

                    setFormData({
                        ...formData,
                        duration: formData.duration || 0,
                        title: data?.title,
                        description: data?.description || formData?.description,
                        publisher_name: data?.publisherName || formData?.publisher_name || prefilledData?.publisher_name,
                        publisher_logo: data?.publisherLogo || formData.publisher_logo | prefilledData?.publisher_logo,
                        date_published: datePublished && !isNaN(datePublished.getTime()) ? datePublished : formData?.date_published || undefined,
                        image_url: data?.imageUrl || formData.image_url,
                    });
                }
                console.log("scrapped data: ", data);
            })
            .catch(e => alert.show(displayAPIError(e)))
            .finally(() => setIsScraping(false));
    };

    const saveContent = () => {
        setIsSaving(true);
        if (isEdit) {
            const data = prepareFieldsForUpdate(content, formData, [
                ["channel_title", "channel_url"],
                ["publisher_logo", "publisher_name"]
            ]);

            const request = {
                id: content.id,
                ...data,
                ...(((Array.isArray(content.images) && content.images[0]) || "") !== formData.image_url && { image_url: formData.image_url })
            };
            // no changes
            if (Object.keys(request).length === 1) {
                setIsSaving(false);
                return;
            }
            updateVideo(request, user.access_token)
                .then(onSuccess)
                .catch(e => alert.show(displayAPIError(e)))
                .finally(() => setIsSaving(false));
        } else {
            const videoData = {
                ...formData,
                story_id: storyId
            };
            createAndPinVideoToAStory(videoData, user.access_token)
                .then(onSuccess)
                .catch(e => alert.show(displayAPIError(e)))
                .finally(() => setIsSaving(false));
        }
    };

    const onInputChange = e => {
        if (e.target.type === "radio") {
            setFormData({ ...formData, bias: e.target.value });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleDurationChange = e => {
        if (e.target.name === "duration") {
            const duration = parseDuration(e.target.value) || e.target.value;
            setFormData({ ...formData, [e.target.name]: duration });
        }
    };

    const onDateChange = updatedData => {
        setFormData({ ...formData, date_published: updatedData });
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Update Video">
            <div className="modal-body">
                <div className="form-group has-danger">
                    <label className="form-control-label" htmlFor="content_url">
                        URL:
                    </label>
                    <div className="input-group mb-3">
                        <input
                            onChange={onInputChange}
                            disabled={isEdit}
                            value={formData.content_url}
                            type="text"
                            id="content_url"
                            name="content_url"
                            className="form-control"
                        />
                        <div className="input-group-append">
                            <span onClick={onScrapeClick} className="input-group-text pointer">
                                {isScraping ? (
                                    <div className="mx-3 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    "Scrape"
                                )}
                            </span>
                        </div>
                    </div>
                    <>
                        <label className="form-control-label" htmlFor="title">
                            Title:
                        </label>
                        <textarea onChange={onInputChange} value={formData.title} rows="2" id="title" name="title" className="form-control" />

                        <label className="form-control-label mt-2" htmlFor="description">
                            Description:
                        </label>
                        <textarea
                            onChange={onInputChange}
                            value={formData.description}
                            name="description"
                            id="description"
                            rows="3"
                            className="form-control"
                        />

                        <label className="form-control-label mt-2" htmlFor="duration">
                            Duration: {parseInt(formData.duration) ? formData.duration + " seconds" : ""}
                        </label>
                        <input
                            type="text"
                            // onChange={onInputChange}
                            onBlur={handleDurationChange}
                            defaultValue={formData.duration}
                            name="duration"
                            id="duration"
                            className="form-control"
                        />

                        <label className="form-control-label mt-2" htmlFor="channel_title">
                            Channel Title
                        </label>
                        <input
                            type="text"
                            onChange={onInputChange}
                            value={formData.channel_title}
                            name="channel_title"
                            id="channel_title"
                            className="form-control"
                        />

                        <label className="form-control-label mt-2" htmlFor="channel_url">
                            Channel URL
                        </label>
                        <input
                            type="url"
                            onChange={onInputChange}
                            value={formData.channel_url}
                            name="channel_url"
                            id="channel_url"
                            className="form-control"
                        />
                    </>
                    <label className="form-control-label mt-2" htmlFor="bias">
                        Bias:
                    </label>
                    <select onChange={onInputChange} defaultValue={formData.bias} name="bias" id="bias" className="custom-select d-inline">
                        <option value="">Select one</option>
                        <option value="left">Left</option>
                        <option value="middle">Middle</option>
                        <option value="right">Right</option>
                        {/* <option value="satire">Satire</option> */}
                    </select>
                    <>
                        {/* <label className="form-control-label mt-2" htmlFor="author">
                            Author:
                        </label>
                        <input
                            onChange={onInputChange}
                            value={formData.author}
                            type="text"
                            id="author"
                            name="author"
                            className="form-control"
                        /> */}

                        <label className="form-control-label mt-2" htmlFor="publisher_name">
                            Publisher name:
                        </label>
                        <input
                            onChange={onInputChange}
                            value={formData.publisher_name}
                            type="text"
                            id="publisher_name"
                            name="publisher_name"
                            className="form-control"
                        />

                        <label className="form-control-label mt-2" htmlFor="publisher_logo">
                            Publisher Logo:
                        </label>
                        <input
                            onChange={onInputChange}
                            value={formData.publisher_logo}
                            type="text"
                            id="publisher_logo"
                            name="publisher_logo"
                            className="form-control"
                        />

                        <label className="form-control-label mt-2" htmlFor="date_published">
                            Date published:
                        </label>
                        <div className="w-100">
                            <DatePicker
                                className="form-control bg-white pl-2"
                                selected={formData.date_published ? new Date(formData.date_published) : null}
                                onChange={onDateChange}
                                placeholderText="Click to select a date"
                            />
                        </div>

                        <label className="form-control-label mt-2" htmlFor="image_url">
                            Image URL:
                        </label>
                        <input
                            onChange={onInputChange}
                            value={formData.image_url}
                            type="text"
                            id="image_url"
                            name="image_url"
                            className="form-control"
                        />
                    </>
                </div>
                <div className="position-relative">
                    {!formData.image_url && <span className="position-absolute translate-middle">No image selected</span>}
                    {formData.image_url && !isValidHttpUrl(formData.image_url) && (
                        <span className="position-absolute translate-middle">Invalid image URL</span>
                    )}
                    <img
                        style={{ objectFit: "cover", width: "400px", height: "200px" }}
                        className="img-thumbnail"
                        src={isValidHttpUrl(formData.image_url) ? formData.image_url : noImageDataURI}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveContent}>
                    {isSaving ? (
                        <div className="d-inline-flex">
                            <span className="mx-2 spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Save</span>
                            </span>
                        </div>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </Modal>
    );
}

function prepareFieldsForUpdate(original, form, boundedFieldsList) {
    const response = {};

    for (const field in form) {
        const originalValue = original[field];
        const formValue = form[field];

        if (!originalValue && !formValue) {
            continue;
        }
        if (originalValue === formValue) {
            continue;
        }

        response[field] = formValue;

        boundedFieldsList.forEach(boundedSet => {
            if (boundedSet.includes(field)) {
                const boundedListMinusField = boundedSet.filter(b => b !== field);
                boundedListMinusField.forEach(boundedField => {
                    if (!response[boundedField]) {
                        response[boundedField] = form[boundedField];
                    }
                });
            }
        });
    }
    return response;
}

// prettier-ignore
ContentModal.propTypes = {
    type: PropTypes.oneOf(["new", "edit"]).isRequired,
    content: PropTypes.object,
    storyId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

function prefillValuesForWellknownDomains(url) {
    if (url?.startsWith("https://youtube.com")) {
        return {
            publisher_name: "YouTube",
            publisher_logo: "https://logo.clearbit.com/youtube.com"
        };
    }
    if (url?.startsWith("https://facebook.com")) {
        return {
            publisher_name: "Facebook",
            publisher_logo: "https://logo.clearbit.com/facebook.com"
        };
    }
    if (url?.startsWith("https://rumble.com")) {
        return {
            publisher_name: "Rumble",
            publisher_logo: "https://logo.clearbit.com/rumble.com"
        };
    }
}

export default ContentModal;
